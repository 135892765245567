import dynamic from 'next/dynamic';

import BlogLayout from '../templates/BlogLayout';
import Layout from '../templates/layout';
import Authenticator from '../templates/partials/Authenticator';

const SavaLayout = dynamic(import(':templates/SavaLayout'));
const SavaSectionBuilder = dynamic(import(':templates/SavaSectionBuilder'));

export const returnTemplate = (props, Component) => {
  const {
    construct, globals, mode, previewData, requiresAuth,
  } = props;

  if (construct.useNewBlocks) {
    return (
      <SavaLayout
        globals={globals}
        mode={mode}
        previewData={previewData}
      >
        <SavaSectionBuilder blocks={construct.blocks} />
      </SavaLayout>
    );
  }

  if (requiresAuth) {
    return (
      <Authenticator
        action="Redirecting"
        endpoint="www-vercel-authenticate"
      />
    );
  }

  return (
    <Layout
      {...globals}
      alternativePages={construct?.alternativePages}
      meta={construct?.meta}
      mode={mode}
      previewData={previewData}
    >
      <Component
        {...construct}

        // we prefer a `pageLocale` if available - this is to support the "requested locale not available" experience.
        locale={mode?.pageLocale || mode?.locale}
        pageLocaleDefaulted={mode?.pageLocaleDefaulted}
      />
    </Layout>
  );
};

export const returnBlogTemplate = (props, Component) => {
  const {
    construct, globals, mode, previewData, requiresAuth,
  } = props;

  return requiresAuth ? (
    <Authenticator
      action="Redirecting"
      endpoint="www-vercel-authenticate"
    />
  ) : (
    <BlogLayout
      {...globals}
      alternativePages={construct?.alternativePages}
      meta={construct?.meta}
      mode={mode}
      previewData={previewData}
    >
      <Component
        {...construct}

        // we prefer a `pageLocale` if available - this is to support the "requested locale not available" experience.
        globals={globals}

        // this isnt used on blog but we're to scared to remove it...
        locale={mode?.pageLocale || mode?.locale}
        mode={mode}
        pageLocaleDefaulted={mode?.pageLocaleDefaulted}
      />
    </BlogLayout>
  );
};
