import React from 'react';

import axios from 'axios';
import { withRouter } from 'next/router';
import Cookies from 'universal-cookie';

import { getUrlParam } from '../../components/helpers';

class Authenticator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const { endpoint } = this.props;

    const cookies = new Cookies();
    const previewSecret = cookies.get('previewSecret');

    const redirects = {
      'vr-test': '/backstop_data/html_report/index.html',
      'www-vercel-authenticate': window.location.pathname,
      'www-vercel-preview': window.location.pathname,
    };

    const args = { 'vr-test': { url: getUrlParam('url') } };

    try {
      const response = await axios.post(`/api/${endpoint}`, {
        ...args[endpoint],
        secret: previewSecret,
      });
      if (response.status === 200 && redirects[endpoint]) {
        window.location.replace(redirects[endpoint]);
      }
    } catch {
      this.setState({ isDenied: true });
    }
  }

  render() {
    const insideContent = this.state.isDenied
      ? (
        <>
          <h2 style={{ marginBottom: 24 }}>Authentication Denied</h2>
          <p
            style={{
              fontSize: 18,
              lineHeight: '28px',
              opacity: 0.7,
            }}
          >
            Please make sure you have the proper extension installed
          </p>
        </>
      )
      : (
        <>
          <h2 className="ellipses-loading" style={{ marginBottom: 24 }}>
            Redirecting
          </h2>
          <p
            style={{
              fontSize: 18,
              lineHeight: '28px',
              opacity: 0.7,
            }}
          >
            {this.props.successMessage}
          </p>
        </>
      );

    return (
      <section
        style={{
          backgroundColor: '#502d3c',
          height: '100vh',
          paddingBottom: 160,
          paddingTop: '40vh',
        }}
      >
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .ellipses-loading:after {
            overflow: hidden;
            display: inline-block;
            vertical-align: bottom;
            -webkit-animation: ellipsis steps(4,end) 900ms infinite;
            animation: ellipsis steps(4,end) 900ms infinite;
            content: "\\2026";
            width: 0px;
          }
          
          @keyframes ellipsis {
            to {
              width: 1.25em;
            }
          }
          
          @-webkit-keyframes ellipsis {
            to {
              width: 1.25em;
            }
          }
          `,
          }}
        />
        <div
          style={{
            margin: '0 auto',
            maxWidth: 550,
            width: '93%',
          }}
        >
          <div
            style={{
              color: '#fff',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <div>{insideContent}</div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Authenticator);
